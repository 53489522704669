<template>
  <PDialog
    :loading="isLoading"
    :title="title"
    classes="w-10/12 md:w-4/5 xl:w-2/5"
    @close="$emit('close')"
  >

    <PDialogContent v-if="order">

      <PLoader v-if="isLoadingOrder" />

      <template v-else>

        <div
          v-if="order.status === 'Registered'"
          class="flex flex-col space-y-4"
        >

          <PText label="Status">{{ order.status }}</PText>

          <PDatePicker
            v-model="order.deliveryDate"
            :required="true"
            label="Leveringsdato"
            @input="isDirty = true"
          />

          <PSelect
            v-model="order.containerTypeId"
            :items="containerTypes"
            itemText="name"
            itemValue="id"
            label="Type"
            @input="isDirty = true"
          />

          <PSelect
            v-model="order.containerColorId"
            :disabled="containerColors.length === 0"
            :items="containerColors"
            itemText="name"
            itemValue="id"
            label="Farge"
            @input="isDirty = true"
          />

          <PInput
            v-model="order.customerReference"
            label="Referanse"
            @input="isDirty = true"
          />

          <PTextarea
            v-model="order.customerComment"
            label="Kommentar"
            @input="isDirty = true"
          />

        </div>

        <div
          v-else
          class="flex flex-col space-y-4"
        >
          <PText label="Status">{{ order.status }}</PText>
          <PText label="Container ID">{{ order.containerId }}</PText>
          <PText label="Lagerlokasjon">{{ order.locationName }}</PText>
          <PText label="Leveringsdato">{{ order.deliveryDate | date }}</PText>
          <PText label="Transportør">{{ order.vendorName }}</PText>
          <PText label="Kommentar">{{ order.containerComment }}</PText>
        </div>

      </template>

    </PDialogContent>

    <PDialogActions :split="order && order.status === 'Registered'">

      <PButton
        v-if="order && order.status === 'Registered'"
        :disabled="isSubmitting"
        :loading="isDeleting"
        color="danger"
        @click="onDelete"
      >
        Slett bestilling
      </PButton>

      <div class="flex space-x-2">

        <PButton
          :disabled="isSubmitting || isDeleting"
          color="secondary"
          @click="onClose"
        >
          Lukk
        </PButton>

        <PButton
          v-if="order && order.status === 'Registered'"
          :disabled="!isDirty || isDeleting"
          :loading="isSubmitting"
          @click="onSubmit"
        >
          Lagre endringer
        </PButton>

      </div>
    </PDialogActions>
  </PDialog>
</template>

<script>

import http from "@/http"
import { pick } from "lodash"

export default {

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      order: null,
      orderTypes: [],
      isLoadingOrder: false,
      isSubmitting: false,
      isDeleting: false,
      isDirty: false,
      containerColors: [],
      containerTypes: []
    }
  },

  computed: {

    title () {
      return this.id ? this.id : "Container"
    }

  },

  methods: {

    formatNumber (number) {
      return new Intl.NumberFormat(this.$i18n.locale.replace('-sw', ''), { minimumFractionDigits: 0 }).format(number)
    },

    async onSubmit () {

        this.isSubmitting = true

        try {

          await http.post("ContainerOrder", {
            ...pick(this.order, [
              "id",
              "containerOrderTypeId",
              "customerId",
              "locationId",
              "containerTypeId",
              "containerColorId",
              "customerReference",
              "customerComment",
              "deliveryDate"
            ])
          })

          this.$emit("close")

        } catch (error) {

          this.$notifyError(error.reason)

        }

        this.isSubmitting = false

    },

    async onDelete () {
      this.isDeleting = true

      try {

        await http.delete("ContainerOrder", { params: { id: this.order.id }})

        this.$emit("close")

      } catch (error) {

        this.$notifyError(error.reason)

      }

      this.isDeleting = false
    },

    onClose () {
      this.$emit("close")
    },

    async getOrder (id) {

      this.isLoading = true

      try {

        this.isLoadingOrder = true
        this.isLoadingOrderTypes = true

        http.get(`ContainerOrder?id=${id}`)
            .then(order => {
              this.order = order
              this.isLoadingOrder = false
            })

        http.get("ContainerColors")
            .then(containerColors => {
              this.containerColors = containerColors
            })

        http.get("ContainerTypes")
            .then(containerTypes => {
              this.containerTypes = containerTypes
            })

      } catch (error) {
        this.$notifyError("", error.reason)
      }

      this.isLoading = false

    }

  },

  async created () {
    await this.getOrder(this.id)
  }


}

</script>

<style scoped>

  th, td {
    @apply p-2;
  }

  tr {
    @apply bg-gray-200;
  }

  tr:nth-child(odd) {
    @apply bg-gray-300;
  }

</style>