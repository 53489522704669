<template>
  <div class="flex flex-col flex-1 overflow-y-scroll bg-white border border-gray-300 rounded">

    <PLoader v-if="isLoading" />

    <POrderGridTable          
      v-else
      :cols="cols"
      :filters="filters"
      :orders="orders"        
      :totalCount="orders.length"
      :loading="isLoading"
      :expandable="false"
      :showFooter="false"          
      @click="onClick"      
    >
    </POrderGridTable>

    <portal to="filters">
      <POrderFilters v-model="filters" />
    </portal>

    <portal to="actions">
      <PButton 
        @click="createContainerOrdersDialogVisible = true"         
        color="primary"
      >
        Ny bestilling
      </PButton>
    </portal>

    <CreateContainerOrdersDialog
      v-if="createContainerOrderDialogVisible"
      @close="onCloseCreateContainersOrderDialog"
    />

    <EditContainerOrderDialog
      v-if="editContainerOrderDialogVisible"
      @close="onCloseEditContainerOrderDialog"
      :id="selectedOrderId"
    />

  </div>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
// import { last, forEach } from "lodash"
import POrderGridTable from "@/components/ordergrid/POrderGridTable"
import CreateContainerOrdersDialog from "../dialogs/CreateContainerOrdersDialog"
import EditContainerOrderDialog from "../dialogs/EditContainerOrderDialog"

export default {
  
  props: {
    id: {
      type: String,
      required: false
    }
  },

  components: {
    POrderGridTable,
    CreateContainerOrdersDialog,
    EditContainerOrderDialog
  },

  data () {
    return {
      isLoading: false,
      orders: [],
      createContainerOrdersDialogVisible: false,
      editContainerOrderDialogVisible: false,
      selectedOrderId: "",
      cols: [        
        { text: "Ordrenr.", value: "id", component: "p-display-text", width: 110, align: "center" },        
        { text: "ID", value: "containerId", component: "p-display-text", width: 110, align: "center", },
        { text: "Type", value: "containerTypeName", component: "p-display-text", align: "left" },        
        { text: "Lagerlokasjon", value: "locationName", component: "p-display-text", align: "left" },        
        { text: "Leveringsdato", value: "deliveryDate", component: "p-display-date", width: 140, align: "center" },        
        { text: "Transportør", value: "vendorName", component: "p-display-text", align: "left" },        
        { text: "Kommentar", value: "customerComment", component: "p-display-text" },        
        { text: "Status", value: "status", component: "p-display-container-order-status", align: "center" },        
      ],      
      filters: {
        sendLower: { value: "" },
        sendUpper: { value: "" },
      },   
      orderBy: {
        field: "dateArrived",
        descending: true
      },
    }
  },  

  computed: {
    
    ...mapGetters([
      "customer", 
      "location"
    ]),
    
    args () {

      return {
        locationId: this.location.id,
        filters: this.filters
      }
    }

  },

  methods: {


    onClick (order) {
      this.selectedOrderId = order.id
      this.editContainerOrderDialogVisible = true
    },

    async onCloseCreateContainerOrdersDialog () {
      this.createContainerOrdersDialogVisible = false
      await this.getOrders()
    },
    
    async onCloseEditContainerOrderDialog () {
      this.editContainerOrderDialogVisible = false
      await this.getOrders()
    },

    async getOrders () {

        this.isLoading = true

        try {

          let params = {            
            containerOrderTypeId: "TO"
          }

          if (this.filters.sendLower.value) {
            params.dateLower = this.filters.sendLower.value
          }

          if (this.filters.sendUpper.value) {
            params.dateUpper = this.filters.sendUpper.value
          }

          this.orders = await http.get("ContainerOrders", { params })

          // forEach(orders, order => {
          //   const lastTransaction = last(order.transactions)
          //   if (lastTransaction) {
          //     order.lastActivity = `${ this.$options.filters.date(lastTransaction.createdLocal) } - ${lastTransaction.transactionTypeName}`
          //   }
          // })

          // this.orders = orders

        } catch (error) {          
          this.$notifyError("", error.reason)
        }

        this.isLoading = false
    }
  },


  watch: {
    args: {
      handler: async function () {
        await this.getOrders ()
      }, immediate: true
    }
  }

}
</script>
